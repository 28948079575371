import { PermissionCheckMixin, VIEW_PERMISSION, CFR_OOS, CFR_ADDATE } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "body" }, [_vm.isOnStaging ? _c("portal", { attrs: { "to": "facility-select" } }, [_c("a-tag", { attrs: { "color": "red" } }, [_vm._v("Test Application")])], 1) : _vm._e(), _c("portal", { attrs: { "to": "sider-items" } }, [_c("div", { staticClass: "kh-application p-4" }, [_c("p", { staticClass: "m-0" }, [_vm._v("Service Level Maintenance")]), _c("h5", { staticClass: "mb-0" }, [_vm._v("Service Level Maintenance")])]), _c("a-menu", { attrs: { "open-keys": _vm.isSidebarCollapsed ? _vm.collapsedOpenKeys : _vm.openKeys, "selected-keys": _vm.selectedKeys, "mode": "inline", "theme": "light", "inline-collapsed": _vm.isSidebarCollapsed } }, [_vm._l(_vm.dataMenu, function(item) {
    return [item.child.length === 0 && (item.key === "home" || _vm.$can(_vm.permissions.view, item.page) && !item.hidden) ? [_c("a-menu-item", { key: item.key, on: { "click": function($event) {
      return _vm.clickItem($event);
    } } }, [_c("router-link", { attrs: { "to": item.path } }, [_c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1)], 1)] : _vm._e(), item.child.length > 0 && _vm.checkChildPermission(item.child) ? [_c("a-sub-menu", { key: item.key, on: { "titleClick": function($event) {
      return _vm.clickParentItem($event);
    } } }, [_c("span", { attrs: { "slot": "title" }, slot: "title" }, [_c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1), _vm._l(item.child, function(subChild1) {
      return [subChild1.child.length === 0 && _vm.showMenuItem(subChild1) && !subChild1.hidden ? [_c("a-menu-item", { key: subChild1.key, style: {
        paddingLeft: !_vm.isSidebarCollapsed ? "24px" : "auto"
      }, on: { "click": function($event) {
        return _vm.clickItem($event);
      } } }, [_c("router-link", { attrs: { "to": subChild1.path } }, [!_vm.isSidebarCollapsed ? _c("a-icon", { attrs: { "type": subChild1.icon } }) : _vm._e(), _c("span", [_vm._v(_vm._s(subChild1.title))])], 1)], 1)] : _vm._e(), subChild1.child.length > 0 && _vm.checkChildPermission(subChild1.child) ? [_c("a-sub-menu", { key: subChild1.key, on: { "titleClick": function($event) {
        return _vm.clickParentItem($event);
      } } }, [_c("span", { attrs: { "slot": "title" }, slot: "title" }, [_c("a-icon", { attrs: { "type": subChild1.icon } }), _c("span", [_vm._v(_vm._s(subChild1.title))])], 1), _vm._l(subChild1.child, function(subChild2) {
        return [_vm.$can(_vm.permissions.view, subChild2.page) ? _c("a-menu-item", { key: subChild2.key, on: { "click": function($event) {
          return _vm.clickItem($event);
        } } }, [_c("router-link", { attrs: { "to": subChild2.path } }, [_vm._v(" " + _vm._s(subChild2.title) + " ")])], 1) : _vm._e()];
      })], 2)] : _vm._e()];
    })], 2)] : _vm._e()];
  })], 2)], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
var Cfr_vue_vue_type_style_index_0_scoped_true_lang = "";
const env = "#{VUE_APP_ENV}#";
const __vue2_script = {
  name: "Cfr",
  mixins: [PermissionCheckMixin],
  props: {
    isSidebarCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      collapsedOpenKeys: [],
      selectedKeys: [],
      openKeys: [],
      permissions: { view: VIEW_PERMISSION },
      isOnStaging: env == "staging",
      dataMenu: [
        {
          title: "Back to Main Menu",
          path: "/",
          key: "home",
          icon: "appstore",
          child: []
        },
        {
          title: "Out Of Stocks Maintenance",
          path: "/cfr/oo-out-of-stocks-stagings",
          key: "oo-out-of-stocks-stagings",
          icon: "stock",
          page: CFR_OOS,
          child: []
        },
        {
          title: "Cuts Reason/Comment",
          path: "/cfr/cut-reason-comment",
          key: "cut-reason-comment",
          icon: "message",
          page: CFR_OOS,
          child: []
        },
        {
          title: "Actual Delivery Date",
          path: "/cfr/actual-delivery-date",
          key: "actual-delivery-date",
          icon: "calendar",
          page: CFR_ADDATE,
          child: []
        }
      ]
    };
  },
  computed: {
    topLevelKeys() {
      return this.dataMenu.filter((item) => item.child.length).map((item) => item.key);
    }
  },
  metaInfo: {
    title: `Service Level Maintenance`
  },
  watch: {
    isSidebarCollapsed(newVal) {
      if (!newVal) {
        this.collapsedOpenKeys = [];
      }
    },
    sidebarHidden(newVal) {
      if (newVal) {
        this.collapsedOpenKeys = [];
      }
    }
  },
  async created() {
    this.updateMenu();
    this.$emit("collapsedMounted", false);
    this.$context.setWebAnalyticsContext();
  },
  methods: {
    showMenuItem(menuItem) {
      if (!menuItem.page || this.$can(this.permissions.view, menuItem.page)) {
        return true;
      }
      return false;
    },
    clickItem(e) {
      this.selectedKeys = [e.key];
      if (this.isSidebarCollapsed)
        this.collapsedOpenKeys = [];
    },
    clickParentItem(e) {
      const { key } = e;
      const {
        isSidebarCollapsed: collapsed,
        collapsedOpenKeys,
        openKeys,
        topLevelKeys
      } = this;
      const hasKey = collapsed ? collapsedOpenKeys.includes(key) : openKeys.includes(key);
      if (collapsed) {
        if (topLevelKeys.includes(key)) {
          this.collapsedOpenKeys = !hasKey ? [key] : [];
        } else {
          this.collapsedOpenKeys = !hasKey ? [...collapsedOpenKeys, key] : collapsedOpenKeys.filter((openKey) => openKey !== key);
        }
        return;
      }
      this.openKeys = !hasKey ? [...openKeys, key] : openKeys.filter((openKey) => openKey !== key);
    },
    checkChildPermission(listMenuItem) {
      return listMenuItem.some((itemChild) => {
        if (itemChild.child.length > 0) {
          this.checkChildPermission(itemChild.child);
          return true;
        } else {
          return this.showMenuItem(itemChild);
        }
      });
    },
    updateMenu() {
      const { path, meta } = this.$route.matched[1];
      this.selectedKeys = [path.substring(path.lastIndexOf("/") + 1)];
      if (typeof meta === "string") {
        const [menuMeta] = meta.split(";");
        this.openKeys = menuMeta.includes("_") ? menuMeta.split("_") : [menuMeta];
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "c39779e0", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var Cfr = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { Cfr as default };
